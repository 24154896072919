<template>
  <div class="user-basic-setting">
    <van-nav-bar
      title="基本设置"
      left-arrow
      safe-area-inset-top
      placeholder
      @click-left="onClickLeft"
      :style="{'padding-top': statusBarHeight + 'px'}"
    />
    <van-cell-group>
      <van-form @submit="onSubmit">
        <div class="user-basic-box">
          <van-field label="账号" :value="userProfile['username']" readonly />
        </div>
        <div class="user-basic-box">
          <van-field
            v-model="nickname"
            name="昵称"
            label="昵称"
            placeholder="给自己起个名字"
            :rules="[{ required: true, message: '请填写昵称' }]"
          />
        </div>
        <div class="user-basic-box">
          <van-field
            readonly
            v-model="form.dataRegionName"
            name="默认数据域"
            label="默认数据域"
            right-icon="arrow"
            @click="dataRegionPickVisible = true"
          />
          <van-action-sheet
            v-model="dataRegionPickVisible"
            :actions="dataRegionItems"
            @select="onDataRegionSelect" />
        </div>
        <div class="user-basic-box">
          <div>
            <van-field
              readonly
              v-model="form.defaultPageType"
              name="默认主页"
              label="默认主页"
              right-icon="arrow"
              @click="defaultPageTypesVisible = true"
            />
            <van-action-sheet
              v-model="defaultPageTypesVisible"
              :actions="defaultPageTypes"
              @select="onDefaultPageTypeSelect" />
          </div>
          <div v-if="form.defaultPageType === '搜索页面'">
            <van-field
              v-model="form.keyword"
              name="关键词"
              label="关键词"
              key="关键词"
              placeholder="请输入关键词（可选项）"
            />
          </div>
          <div v-if="form.defaultPageType === '网页链接'">
            <van-field
              v-model="form.webLink"
              name="首页链接地址"
              label="首页链接地址"
              key="首页链接地址"
              placeholder="请输入..."
              :rules="[{ required: true }]"
            />
          </div>
          <div v-if="form.defaultPageType === '仪表盘'">
            <van-field
              readonly
              v-model="form.dashboardName"
              name="仪表盘名称"
              label="仪表盘名称"
              key="仪表盘名称"
              placeholder="请选择..."
              right-icon="arrow"
              @click="dashboardVisible = true"
              :rules="[{ required: true }]"
            />
            <van-action-sheet
              v-model="dashboardVisible"
              :actions="actionDashboardList"
              @select="onDashboardSelect" />
          </div>
        </div>
        <div class="user-basic-box" v-if="deviceType === 'android'">
          <div>
            <van-field
              readonly
              v-model="form.defaultLanguage"
              name="语音缺省方言"
              label="语音缺省方言"
              right-icon="arrow"
              @click="defaultLanguageVisible = true"
            />
            <van-action-sheet
              v-model="defaultLanguageVisible"
              :actions="defaultLanguageTypes"
              @select="onDefaultLanguageSelect" />
          </div>
        </div>
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit">保存</van-button>
        </div>
      </van-form>

    </van-cell-group>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import _ from 'lodash'
export default {
  name: 'BasicSetting',
  components: {},
  data () {
    return {
      nickname: '',
      dataRegionPickVisible: false,
      defaultPageTypesVisible: false,
      dashboardVisible: false,
      defaultLanguageVisible: false,
      form: {
        dataRegionId: '',
        dataRegionName: '',
        defaultPageType: '',
        keyword: '',
        webLink: '',
        dashboardId: '',
        dashboardName: '',
        defaultLanguage: '',
        languageType: {}
      },
      defaultPageTypes: [{ name: '系统主页' }, { name: '搜索页面' }, { name: '网页链接' }, { name: '仪表盘' }],
      defaultLanguageTypes: [],
      dashboardList: []
    }
  },
  computed: {
    ...mapState({
      userProfile: state => state.user.profile,
      settings: state => state.user.settings,
      statusBarHeight: state => state.global.statusBarHeight,
      dataRegionItems: state => state.dataRegion.dataList,
      deviceType: state => state.global.deviceType,
      inXbiApp: state => state.global.inXbiApp
    }),
    mobileSettings () {
      return this.settings['mobile'] || {}
    },
    actionDashboardList () {
      return this.dashboardList.filter(item => item['dataRegionId'] === this.form.dataRegionId)
    }
  },
  methods: {
    getUserProfile () {
      this.$api.user.getUserProfile().then(res => {
        this.$store.commit('user/setProfile', res)
        this.nickname = this.userProfile.nickname
      }).catch((e) => {})
    },
    getAvailableLangs () {
      this.$api.speech.getAvailableLangs().then(res => {
        this.defaultLanguageTypes = res.data
      })
    },
    getSettings () {
      this.$api.user.getSettings().then(res => {
        if (res && res['settings'] && res['settings']) {
          this.$store.commit('user/setSettings', JSON.parse(res['settings']))
          this.initSettings()
        }
      }).catch((e) => {})
    },
    initSettings () {
      this.form.dataRegionId = this.mobileSettings.dataRegionId || this.dataRegionItems[0]['id']
      this.form.defaultPageType = this.mobileSettings.defaultPageType
      this.form.languageType = this.mobileSettings.languageType
      this.form.defaultLanguage = this.mobileSettings.defaultLanguage
      if (!this.defaultLanguageTypes.find(item => item.name === this.form.defaultLanguage)) { // 如果之前存的语音类型已失效或不存在，换为第一个普通话
        this.form.defaultLanguage = this.defaultLanguageTypes[0]['name']
        this.form.languageType = {
          language: this.defaultLanguageTypes[0]['language'],
          accent: this.defaultLanguageTypes[0]['accent']
        }
      }
      this.saveLangsToApp(this.form.languageType.language, this.form.languageType.accent)
      if (this.form.defaultPageType === '搜索页面') {
        this.form.keyword = this.mobileSettings.extraParam
      } else if (this.form.defaultPageType === '网页链接') {
        this.form.webLink = this.mobileSettings.extraParam
      } else if (this.form.defaultPageType === '仪表盘') {
        this.form.dashboardId = this.mobileSettings.extraParam
      }
    },
    getDashboardList () {
      this.$api.dashboard.getDashboardList({
        title: '',
        page: 0
      }).then(res => {
        this.dashboardList = res['content'].filter(item => {
          item['name'] = item['title']
          return JSON.parse(item['data']).platform === 'mobile'
        })
      })
    },
    onClickLeft () {
      window.history.go(-1)
    },
    onSubmit () {
      if (this.inXbiApp && this.deviceType === 'ios') {
        this.$toast('免登录不能修改账户信息哦~')
        return
      }
      let extraParam = ''
      if (this.form.defaultPageType === '搜索页面' && !_.isEmpty(this.form.dataRegionId)) {
        extraParam = this.form.keyword
      } else if (this.form.defaultPageType === '网页链接') {
        extraParam = this.form.webLink
      } else if (this.form.defaultPageType === '仪表盘') {
        extraParam = this.form.dashboardId
      }
      const { dataRegionId, defaultPageType, languageType, defaultLanguage } = this.form
      const settings = Object.assign({}, this.settings, {
        mobile: {
          defaultPageType,
          extraParam,
          dataRegionId,
          languageType,
          defaultLanguage
        }
      })
      this.$api.user.saveSettings(settings).then(res => {
        this.$store.commit('user/setSettings', JSON.parse(res['settings']))
        this.saveLangsToApp(languageType.language, languageType.accent)
        this.$toast.success('设置成功！')
        this.getSettings()
      })
    },
    onDataRegionSelect (item) {
      this.form.dataRegionId = item.id
      this.dataRegionPickVisible = false
    },
    onDefaultLanguageSelect (item) {
      this.form.defaultLanguage = item.name
      this.form.languageType = {
        language: item.language,
        accent: item.accent
      }
      this.defaultLanguageVisible = false
    },
    onDefaultPageTypeSelect (item) {
      this.form.defaultPageType = item.name
      this.defaultPageTypesVisible = false
    },
    onDashboardSelect (item) {
      this.form.dashboardId = item.id
      this.dashboardVisible = false
    },
    saveLangsToApp (language, accent) {
      if (this.deviceType === 'android') { // ios端未增加语音修改功能，故不作修改
        window['XbiApp']['changeMIatLanguage'](language, accent)
      }
    }
  },
  mounted () {
    this.getUserProfile()
    this.getAvailableLangs()
    this.getDashboardList()
    this.getSettings()
  },
  watch: {
    'form.dataRegionId': {
      handler (val) {
        this.form.dataRegionName = this.dataRegionItems.find(item => item.id === val)?.name
      },
      immediate: true
    },
    'form.dashboardId': {
      handler (val) {
        this.form.dashboardName = this.dashboardList.find(item => item.id === val)?.name
      },
      immediate: true
    },
    'dashboardList': {
      handler (arr) {
        if (arr.length > 0 && !_.isEmpty(this.form.dashboardId)) {
          this.form.dashboardName = arr.find(item => item.id === this.form.dashboardId)?.name
        }
      },
      immediate: true

    }
  }
}
</script>
<style lang="less">
.user-basic-setting {
  background-color: #f8f8f8;
  height: 100vh;
  background-image: url("~@/assets/background.png");
  background-size: 100% 40vh;
  background-repeat: no-repeat;
  .van-nav-bar {
    background-color: transparent;
    &::after {
      border-bottom-width: 0
    }
    .van-nav-bar__content {
      height: 40px;
      .van-nav-bar__title {
        font-size: 19px;
        font-weight: bold;
        color: #ffffff;
        font-family: PingFang SC;
      }
      .van-nav-bar__left {
        .van-icon-arrow-left {
          color: #000000;
          font-weight: bold;
          color: #ffffff;
          &::before {
            vertical-align: middle;
          }
        }
      }
    }
  }
  .van-cell-group {
    background-color: transparent;
    overflow: auto;
    padding-top: 15px;
    .user-basic-box {
      margin: 0 15px 15px 15px;
      background-color: #ffffff;
      border-radius: 5px;
      .van-cell {
        border-radius: 5px;
        .van-cell__title {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          .van-cell__label {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #878787;
            line-height: 28px;
          }
        }
        .dashboard-cell-icon {
          .van-icon {
            width: 20px;
            height: 20px;
            background-color: #37acdf14;
            border-radius: 50%;
            text-align: center;

            &::before {
              color: #319DE2;
              vertical-align: middle;
            }
          }
        }
      }
    }
    &::after {
      border-top-width: 0
    }
  }
}
</style>
